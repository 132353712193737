@import '../theme-bootstrap';

.product__inventory-status {
  &-item {
    @include font--subheading();
    @include font-padding(subheading, 24px, 44px);
    @include font-smoothing(antialiased);
    @include appearance(none);
    color: $color-red;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;
    font-size: 24px;
    text-align: center;
    &.temp-out-of-stock {
      padding-top: 10px;
      line-height: 24px;
      .product-full & {
        padding-top: 0;
        padding-bottom: 5px;
      }
    }
  }
  .quickshop-wrapper & {
    padding-top: 0;
  }
}
